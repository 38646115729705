<template>
    <div class="ebook">
      <!-- <title-bar :ifTitleAndMenuShow="ifTitleAndMenuShow"></title-bar> -->
      <div class="read-wrapper">
        <div id="read"></div>
        <div class="mask">
          <div class="left" @click="prevPage"></div>
          <div class="center" @click="toggleTitleAndMenu"></div>
          <div class="right" @click="nextPage"></div>
        </div>
      </div>
      <menu-bar :ifTitleAndMenuShow="ifTitleAndMenuShow"
                :fontSizeList="fontSizeList"
                :defaultFontSize="defaultFontSize"
                @setFontSize="setFontSize"
                @updataMemberUserView="updataMemberUserView"
                :themeList="themeList"
                :defaultTheme="defaultTheme"
                :progress="currentPercentage"
                @setTheme="setTheme"
                :bookAvailable="bookAvailable"
                @onProgressChange="onProgressChange"
                @onProgressInput="onProgressInput"
                :navigation="navigation"
                @jumpTo="jumpTo"
                ref="menuBar"></menu-bar>
    </div>
  </template>

  <script>
  import TitleBar from '@/components/TitleBar'
  import MenuBar from '@/components/MenuBar'
  import Epub from 'epubjs'
//   global.ePub = Epub
  export default {
    components: {
      TitleBar,
      MenuBar
    },
    data() {
      return {
        ifTitleAndMenuShow: false,
        loading:null,
        currentPercentage: 0,
        fontSizeList: [
          { fontSize: 12 },
          { fontSize: 14 },
          { fontSize: 16 },
          { fontSize: 18 },
          { fontSize: 20 },
          { fontSize: 22 },
          { fontSize: 24 }
        ],
        defaultFontSize: 16,
        themeList: [
          {
            name: 'default',
            style: {
              body: {
                'color': '#000', 'background': '#fff'
              }
            }
          },
          {
            name: 'eye',
            style: {
              body: {
                'color': '#000', 'background': '#ceeaba'
              }
            }
          },
          {
            name: 'night',
            style: {
              body: {
                'color': '#fff', 'background': '#000'
              }
            }
          },
          {
            name: 'gold',
            style: {
              body: {
                'color': '#000', 'background': 'rgb(241, 236, 226)'
              }
            }
          }
        ],
        defaultTheme: 0,
        // 图书是否处于可用状态
        bookAvailable: false,
        navigation: {}
      }
    },
    methods: {

      // 根据链接跳转到指定位置
      jumpTo(href) {
        this.rendition.display(href)
        this.hideTitleAndMenu()

        // 使用setTimeout模拟异步等待位置更新
        // setTimeout(() => {
        //   this.updateProgress();
        // }, 1000); // 0毫秒是为了将回调推入下一个事件循环，等待位置真正更新
      },
      hideTitleAndMenu() {
        // 隐藏标题栏和菜单栏
        this.ifTitleAndMenuShow = false
        // 隐藏菜单栏弹出的设置栏
        this.$refs.menuBar.hideSetting()
        // 隐藏目录
        this.$refs.menuBar.hideContent()
      },
      // progress 进度条的数值（0-100）
      onProgressInput(progress) {
        this.currentPercentage = parseInt(progress)
      },
      // progress 进度条的数值（0-100）
      onProgressChange(progress) {
        const percentage = progress / 100
        const location = percentage > 0 ? this.locations.cfiFromPercentage(percentage) : 0
        this.rendition.display(location)
      },
      // 设置主题
      setTheme(index) {
        var that = this;
        this.themes.select(this.themeList[index].name)
        this.defaultTheme = index

      },
      // 注册主题
      registerTheme() {
        this.themeList.forEach(theme => {
          this.themes.register(theme.name, theme.style)
        })
      },
      // 设置字号大小
      setFontSize(fontSize) {
        this.defaultFontSize = fontSize
        if (this.themes) {
          this.themes.fontSize(fontSize + 'px')
        }
      },
      // 切换标题和菜单的显示状态
      toggleTitleAndMenu() {
        this.ifTitleAndMenuShow = !this.ifTitleAndMenuShow
        if (!this.ifTitleAndMenuShow) {
          this.$refs.menuBar.hideSetting()
        }

      },
      // 上一页
      prevPage() {
        if (this.rendition) {
          this.rendition.prev()
        }
      },
      // 下一页
      nextPage() {
        if (this.rendition) {
          this.rendition.next()
        }
      },
      // 当页面内容发生变动时触发回调
      handleLocationChange(loc) {
        let params = {};
        if(this.bookAvailable){
            // 获取当前阅读位置的百分比
            this.currentPercentage = parseInt(loc.percentage.toFixed(2)* 100) ;
            // 更新阅读进度
            params.schedule = this.currentPercentage
        }
        this.updataMemberUserView(params)
      },
      // updateProgress() {
      //   // 获取当前的location对象
      //   const loc = this.rendition.currentLocation();
      //   // 判断location对象是否存在percentage属性
      //   if (loc && typeof loc.start.percentage === 'number') {
      //     this.currentPercentage = parseInt(loc.start.percentage.toFixed(2)* 100) ;
      //     this.$nextTick(() => {
      //       this.currentPercentage = this.currentPercentage;
      //     });
      //   } else {
      //     console.warn('Unable to retrieve reading progression.');
      //   }
      //   let params = {};
      //   this.updataMemberUserView(params)
      // },

      // 更新电子书相关数据
      updataMemberUserView(params) {
        params.code = 300;
        if (this.rendition) {
          // 获取当前的location对象
          const loc = this.rendition.currentLocation();
          // 判断location对象是否存在percentage属性
          if (loc) {
            params.readLocation = loc.start.cfi;
          }
        }
        // 发送消息给 UniApp 页面
        window.parent.postMessage(params, '*');
      },
      // 电子书的解析和渲染
      async showEpub(data) {
        // 设置根元素的字体大小
        const html = document.querySelector('html')
        let fontSize = window.innerWidth / 10
        fontSize = fontSize > 50 ? 50 : fontSize
        html.style.fontSize = fontSize + 'px'
       
        if(this.book){
          this.book.destroy();
        }
        // 生成Book对象
        this.book = new Epub(data.url)
        // 通过Book.renderTo生成Rendition对象
        this.rendition = this.book.renderTo('read', {
          width: window.innerWidth,
          height: window.innerHeight,
          // 兼容iOS
          method: 'default'
        })

        // 通过Rendtion.display渲染电子书
        if(data.readLocation){
          this.rendition.display(data.readLocation)
        }else{
          this.rendition.display()
        }

        // 监听位置变化事件
        this.rendition.on('locationChanged', this.handleLocationChange);
        // 获取Theme对象
        this.themes = this.rendition.themes
        // 设置默认字体
        if(data.typeface){
          this.setFontSize(parseInt(data.typeface))
        }else{
          this.setFontSize(this.defaultFontSize)
        }
        if(data.schedule){
            this.currentPercentage = parseInt(data.schedule)
        }
        // 注册主题
        this.registerTheme()
        // 设置默认主题
        if(data.themeColor){
          this.setTheme(parseInt(data.themeColor))
        }else{
          this.setTheme(this.defaultTheme)
        }
        // Book对象的钩子函数ready
        this.book.ready.then(() => {
          this.navigation = this.book.navigation
          
          // 调整图片
          this.rendition.hooks.content.register(function(contents) {
            contents.addStylesheetRules({
              "img": {
                "max-width": "100% !important",
                "max-height": "100% !important"
              }
            });
          });
          if(this.loading){
            this.loading.close();
          }
          // 生成Locations对象
          return this.book.locations.generate()

        }).then(result => {
          // 保存locations对象
          this.locations = this.book.locations
          // 标记电子书为解析完毕状态
          this.bookAvailable = true

          let params = {
            "schedule": this.currentPercentage
          };
          this.updataMemberUserView(params)
        })
      },

    },
    mounted() {
        const loading = this.$loading({
          lock: true,
          text: '电子书加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.loading = loading;
      // 监听电子书相关数据
      window.addEventListener("message", event => {
        const data = event.data;
        if(data.code && data.code == 200){
          this.showEpub(data);
        }
      });

      // 页面加载完成后发送消息通知uniapp传递电子书相关数据
      window.parent.postMessage({
        "code": 200
      }, '*');


    },

  }
  </script>
  
  <style lang='scss' scoped>
  @import 'assets/styles/global';
  
  .ebook {
    position: relative;
    .read-wrapper {
      .mask {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        display: flex;
        width: 100%;
        height: 100%;
        .left {
          flex: 0 0 px2rem(100);
        }
        .center {
          flex: 1;
        }
        .right {
          flex: 0 0 px2rem(100);
        }
      }
    }
  }
  </style>
  